var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-row',{staticClass:"ministry"},[_c('a-row',{staticClass:"content-title",attrs:{"type":"flex"}},[_c('h2',{staticClass:"flex-3"},[_vm._v(" "+_vm._s(_vm.title[_vm.$i18n.locale])+" ")]),_c('a-row',{staticClass:"flex-no__wrap ml-auto wanted-search-input-wrapper",staticStyle:{"justify-content":"flex-end"},attrs:{"type":"flex"}})],1),_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.tableProps.results,"loading":_vm.loading,"pagination":false,"row-key":(record) => record.id},scopedSlots:_vm._u([{key:"id",fn:function(item){return [_c('span',{staticClass:"td-post-item",on:{"click":function($event){return _vm.$router.push({
            name: 'spelling-mistake-detail',
            params: { id: item.id }
          })}}},[_c('b',[_vm._v(_vm._s(item.id))])])]}},{key:"mistake",fn:function(item){return [_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({
            name: 'spelling-mistake-detail',
            params: {
              id: item.id
            }
          })}}},[_vm._v(" "+_vm._s(item.mistake || "Не указано")+" ")])]}},{key:"description",fn:function(item){return [_vm._v(" "+_vm._s(item.description || "Не указано")+" ")]}},{key:"fixed",fn:function(item){return [(item.fixed === true)?_c('div',[_vm._v("Исправлено")]):_c('div',[_vm._v("Не исправлено")])]}},{key:"date",fn:function(item){return [_vm._v(" "+_vm._s(_vm.moment(item.created_at).format("LLL"))+" ")]}},{key:"operation",fn:function(item){return [_c('a-button',{staticClass:"edit-btn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
            name: 'spelling-mistake-detail',
            params: { id: item.id }
          })}}},[_c('a-icon',{staticClass:"action-btns",attrs:{"type":"edit"}})],1)]}}])}),_c('a-row',{staticClass:"mt-1",staticStyle:{"padding-bottom":"15px"}},[_c('a-pagination',{key:_vm.perPage,attrs:{"current":_vm.page,"default-page-size":_vm.perPage,"total":+_vm.tableProps.count || 0},on:{"change":_vm.toPage}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <a-row class="ministry">
    <a-row class="content-title" type="flex">
      <h2 class="flex-3">
        {{ title[$i18n.locale] }}
      </h2>

      <a-row
        class="flex-no__wrap ml-auto wanted-search-input-wrapper"
        style="justify-content: flex-end"
        type="flex"
      />
    </a-row>

    <a-table
      :columns="columns"
      :data-source="tableProps.results"
      :loading="loading"
      :pagination="false"
      :row-key="(record) => record.id"
    >
      <template slot="id" slot-scope="item">
        <span
          class="td-post-item"
          @click="
            $router.push({
              name: 'spelling-mistake-detail',
              params: { id: item.id }
            })
          "
        >
          <b>{{ item.id }}</b>
        </span>
      </template>

      <template slot="mistake" slot-scope="item">
        <div
          style="cursor: pointer"
          @click="
            $router.push({
              name: 'spelling-mistake-detail',
              params: {
                id: item.id
              }
            })
          "
        >
          {{ item.mistake || "Не указано" }}
        </div>
      </template>

      <template slot="description" slot-scope="item">
        {{ item.description || "Не указано" }}
      </template>

      <template slot="fixed" slot-scope="item">
        <div v-if="item.fixed === true">Исправлено</div>
        <div v-else>Не исправлено</div>
      </template>

      <template slot="date" slot-scope="item">
        {{ moment(item.created_at).format("LLL") }}
      </template>

      <template slot="operation" slot-scope="item">
        <a-button
          type="primary"
          class="edit-btn"
          @click="
            $router.push({
              name: 'spelling-mistake-detail',
              params: { id: item.id }
            })
          "
        >
          <a-icon class="action-btns" type="edit" />
          <!--{{ $t("Edit") }}-->
        </a-button>
      </template>
    </a-table>

    <a-row class="mt-1" style="padding-bottom: 15px">
      <a-pagination
        :key="perPage"
        :current="page"
        :default-page-size="perPage"
        :total="+tableProps.count || 0"
        @change="toPage"
      />
    </a-row>
  </a-row>
</template>

<script>
export default {
  data() {
    return {
      tableProps: {
        results: [],
        count: 0
      },
      title: {
        uz: "Imloviy xato",
        oz: "Имловий хато",
        ru: "Орфографическая ошибка",
        en: "Spelling mistake"
      },
      loading: false,
      perPage: 10,
      page: 1,
      offset: 0,
      columns: [
        {
          title: "ID",
          key: "id",
          width: "90px",
          scopedSlots: { customRender: "id" },
          fixed: "left"
        },
        {
          title: "Орфографическая ошибка",
          key: "mistake",
          width: "30%",
          scopedSlots: { customRender: "mistake" }
        },
        {
          title: "Описание",
          key: "description",
          width: "35%",
          scopedSlots: { customRender: "description" }
        },
        {
          title: this.$t("Status"),
          key: "fixed",
          width: "15%",
          scopedSlots: { customRender: "fixed" }
        },
        {
          title: this.$t("TableCreatedDate"),
          key: "date",
          width: "20%",
          scopedSlots: { customRender: "date" }
        },
        {
          title: this.$t("TableAction"),
          key: "operation",
          fixed: "right",
          scopedSlots: { customRender: "operation" }
        }
      ]
    }
  },
  watch: {
    $route: {
      handler: "fetchAppeals",
      immediate: true
    }
  },
  methods: {
    toPage(arg) {
      this.$router.push({ query: { page: arg } })
    },
    async fetchAppeals() {
      this.loading = true
      this.offset = +this.$route.query.page || 1
      this.page = this.offset
      this.offset = (this.offset - 1) * this.perPage
      try {
        const form = await this.$api.get(
          "/admin/common/spellingmistake/list/",
          {
            params: {
              limit: this.perPage,
              offset: this.offset
            }
          }
        )
        if (form && form.data) {
          this.$set(this, "tableProps", {
            results: form.data.results || [],
            count: form.data.count
          })
        }
      } catch (error) {
        console.error(error)
        this.loading = false
      }

      this.loading = false
    }
  }
}
</script>
<style>
.wanted-search-input-wrapper {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 430px;
  flex: 0 0 430px;
  justify-content: space-around;
}
/*.ministry .ant-table-pagination.ant-pagination {
  display: none;
}*/
</style>
